$colourOne: #1b1b1b;
$colourTwo: #1abc9c;
$fontSize: 14px;

.cookie-policy-v2 {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    background-color: $colourOne;
    color: #fff;
    text-align: center;
    padding: 25px 10px;
    filter: drop-shadow(0px -9px 6px #000000);
    p {
        font-family: Arial, Helvetica, sans-serif;
        color: #fff;
        font-size: $fontSize;
    }
    &__btn {
        font-family: Arial, Helvetica, sans-serif;
        background-color: $colourOne;
        color: #fff;
        border: none;
        transition: background-color 0.3s ease-in-out;
        margin: 0 5px;
        padding: 5px 20px;
        border-radius: 5px;
        outline: none !important;
        font-size: 11px;
        filter: drop-shadow(0px 3px 2px #000000);
        &:hover {
            background-color: $colourTwo;
        }
        &--accept {
            background-color: $colourTwo;
            // font-size: $fontSize + 3;
            // font-weight: 600;
            &:hover {
                background-color: darken($colourTwo, 10%);
            }
        }
    }
}